// overriding mobi stuff we dont need


.mbsc-schedule-all-day-cont {
  display: none;
}

.mbsc-schedule-header-day {
  display: none !important;
}

.mbsc-calendar-controls {
  display: none;
}

// custom styles
.act-calendar {
  background: #606361 !important;
}